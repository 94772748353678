import { Action, createReducer, on } from '@ngrx/store';

import { DatasheetActions, DatasheetApiActions } from '.';
import { DatasheetState, initialSearchState, initialState } from './datasheet.state';

const reducer = createReducer<DatasheetState>(
    initialState,

    on(DatasheetActions.resetState, () => ({
        ...initialState,
    })),

    on(DatasheetActions.resetActiveState, (state) => ({
        ...state,
        active: undefined,
    })),

    on(DatasheetActions.resetSearchState, (state) => ({
        ...state,
        search: { ...initialSearchState },
    })),

    on(DatasheetApiActions.getDatasheetsSuccess, (state, action) => ({
        ...state,
        sorted: [...action.datasheets].sort(
            (s1, s2) =>
                s1.manufacturer.localeCompare(s2.manufacturer) ||
                s1.description.localeCompare(s2.description),
        ),
    })),

    on(DatasheetApiActions.getDatasheetDetailSuccess, (state, action) => {
        const datasheets = state.datasheets.filter((d) => d.id !== action.datasheet.id);
        return {
            ...state,
            datasheets: [...datasheets, action.datasheet],
            datasheetDetailIdToFetch: undefined,
        };
    }),

    on(DatasheetApiActions.createDatasheetSuccess, (state, action) => ({
        ...state,
        active: action.datasheet,
    })),

    on(DatasheetApiActions.updateDatasheetSuccess, (state, action) => ({
        ...state,
        active: action.datasheet,
    })),

    on(DatasheetActions.addActiveDatasheetDetailsId, (state, action) => {
        const activeDatasheetDetailsIds = [...state.activeDatasheetDetailIds, action.datasheetId];
        return {
            ...state,
            activeDatasheetDetailIds: activeDatasheetDetailsIds,
            datasheetDetailIdToFetch: undefined,
        };
    }),
    on(DatasheetActions.setActiveDatasheetDetailsIds, (state, action) => {
        return {
            ...state,
            activeDatasheetDetailIds: [...action.datasheetIds],
            datasheetDetailIdToFetch: undefined,
        };
    }),

    on(DatasheetActions.navigateToDatasheet, DatasheetActions.clearActiveDatasheetDetailsIds, (state) => ({
        ...state,
        activeDatasheetDetailIds: [],
        selectedModbusDatasheetDetails: undefined,
        datasheetDetailIdToFetch: undefined,
    })),
    on(DatasheetActions.markForDetailsFetch, (state, action) => ({
        ...state,
        datasheetDetailIdToFetch: action.datasheetId,
    })),
);

export function datasheetReducer(state: DatasheetState | undefined, action: Action) {
    return reducer(state, action);
}
