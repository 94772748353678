import { createAction, props } from '@ngrx/store';

import { Datasheet, ProtocolType } from '@priva/next-model';

export const resetState = createAction('[Datasheet] Reset datasheet state');

export const resetActiveState = createAction('[Datasheet] Reset active datasheet state');

export const resetSearchState = createAction('[Datasheet] Reset search state');

export const navigateToDatasheet = createAction(
    '[Datasheet] Navigate to datasheet details',
    props<{ solutionId: string; datasheet: Datasheet }>(),
);

export const getDatasheetDetailIfNeeded = createAction(
    '[Datasheet] Get datasheet detail if needed',
    props<{ datasheetId: string; protocolType: ProtocolType }>(),
);

export const markForDetailsFetch = createAction(
    '[Datasheet] Mark for details fetch',
    props<{ datasheetId: string }>(),
);

export const addActiveDatasheetDetailsId = createAction(
    '[Datasheet] Add active datasheet details id',
    props<{ datasheetId: string; protocolType: ProtocolType }>(),
);

export const setActiveDatasheetDetailsIds = createAction(
    '[Datasheet] Set active datasheet details ids',
    props<{ datasheetIds: string[] }>(),
);

export const clearActiveDatasheetDetailsIds = createAction('[Datasheet] Clear active datasheet details ids');

export const addDatasheetToSelectedDatasheets = createAction(
    '[Datasheet] Add datasheet to selected datasheets',
    props<{ datasheetId: string }>(),
);
