import { Datasheet, DatasheetDetail, ModbusDatasheetDetail } from '@priva/next-model';

import { SearchResult } from 'app/search';

export interface DatasheetStateContainer {
    datasheet: DatasheetState;
}

export interface DatasheetState {
    sorted: Datasheet[];
    datasheets: (DatasheetDetail | ModbusDatasheetDetail)[];
    active?: Datasheet;
    search?: SearchState;
    activeDatasheetDetailIds: string[];
    datasheetDetailIdToFetch: string;
}

export interface SearchState {
    results: SearchResult[];
    searchTerms: string[];
}

export const initialSearchState: SearchState = {
    results: [],
    searchTerms: [],
};

export const initialState: DatasheetState = {
    sorted: [],
    datasheets: [],
    search: initialSearchState,
    activeDatasheetDetailIds: [],
    datasheetDetailIdToFetch: undefined,
};
