import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { DatasheetApiEffects } from './datasheet-api.effects';
import { DatasheetThrobberEffects } from './datasheet-throbbers.effects';
import { DatasheetEffects } from './datasheet.effects';
import { datasheetReducer } from './datasheet.reducer';

@NgModule({
    declarations: [],
    imports: [
        StoreModule.forFeature('datasheet', datasheetReducer),
        EffectsModule.forFeature([DatasheetApiEffects, DatasheetEffects, DatasheetThrobberEffects]),
    ],
})
export class DatasheetStateModule {}
